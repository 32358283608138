import React from "react";

export default function Footerr() {
  return (
    <div
      className="bg-dark text-light py-3"
      style={{ position: "absolute", top: "150vh", width: "100%" }}
    >
      <p className="text-center">copyright &copy; todos</p>
    </div>
  );
}
