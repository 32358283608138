import React from 'react';

export default function About() {
  return (
    
    <div>
      <h1>About</h1>
      <p>This is the TodoList app v1.0.0. It is part of a React crash course</p>
    </div>
    );
}
